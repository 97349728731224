module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#000',

  text: '#333333',

  primary: '#212738',
  primaryLight: '#212738',
  primaryDark: '#212738',

  secondary: '#b4b8ab',
  secondaryLight: '#afbcbd',
  secondaryDark: '#c60055',

  grey1: '#e8e8e8',
  grey2: '#c3c3c3',
  grey3: '#898989',
  palegrey: '#fcfbfd',
};
