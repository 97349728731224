import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import config from 'react-reveal/globals';
import preset from '@rebass/preset';

import colors from '../../colors';

import SEO from 'src/components/SEO';

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: inherit;
    }

  body {
    box-sizing: border-box;
    margin: 0;
    overflow-x: hidden;
    width: 100vw;
  }

  #SRLLightbox {
    z-index: 999;
  }
`;

config({ ssrFadeout: true });

const theme = {
  ...preset,
  colors,
  fonts: {
    heading: 'inherit',
    monospace: 'monospace',
  },
};

const Layout = ({ children, style }) => {
  return (
    <ThemeProvider theme={theme}>
      <SEO />
      <GlobalStyle />
      <main style={style}>
        {children}
      </main>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
